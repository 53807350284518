<template>
	<div class="page-container">
		<div class="header">
			<page-header @navBack="navBack" :back="true" :pageTitle="pageTitle"></page-header>
		</div>
		<div class="page-body">
			<div class="content">
				<div class="section-top">
					<!-- <img v-if="params.w==0" :src="require('../../assets/icon-allright'+(appno==1?'':appno)+'.png')" alt="avatar"> -->
					<!-- <img v-else :src="require('../../assets/icon-allright'+(appno==1?'':appno)+'.png')" alt="avatar"> -->
					<h4>确认是{{ name }}本人操作</h4>
					<p class="desc">您在学习过程中，将随机进行人脸验证。若非本人验证，则无法完成课程学习。</p>
					<img class="img-face" :src="require('../assets/bg-face1.png')" alt="avatar">
					<div class="comment" style=" font-weight:bold">
						<p>1.请确认由本人亲自操作</p>
						<p>2.请将脸置于提示框内，并按提示完成任务。</p>
						<p>3.如图(1)所示，避免人脸对比不通过扣除学时。</p>
						<p> 4.请直立于相机前，端正手机拍照！（不要躺着，手机拿正拍照）</p>
					</div>
				</div>
				<div class="section-bottom">
					<van-button class="btn" type="primary" block :loading="uploading" @click="clickAppPlay">开始</van-button>
					<input v-if="!isUseJSPlayPhoto" ref="imageInput" class="img-input" @change="imgChange($event)" type="file" accept="image/*" capture="user">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "../components/page-header.vue";
import {compareFace, wxConfig, wxCompareFace, signIn} from '../api/user';
import Compressor from 'compressorjs';

export default {
	data() {
		return {
			pageTitle: "人脸识别",
			params: {},
			uploading: false,
			avatar: '',
		};
	},
	computed: {
		isUseJSPlayPhoto() {
			if (this.$store.state.cache.screen.wx) return true
			if (this.$route.query.state === 'js') return false
			// if (sessionStorage.getItem('anhuihefei') === '1') {
			// 	return false
			// }
			if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
			return true
		},
		appno() {
			return this.$store.state.app.appno
		},
		name() {
			return this.$store.state.user.name
		},
		courseid() {
			return this.$store.state.user.courseid
		},
		activeSubject() {
			return this.$route.query.activeSubject
		},
		mediaPlayer() {
			return this.$route.query.mediaPlayer
		},
	},
	components: {
		PageHeader,
	},
	mounted() {
		const _this = this
		this.params = this.$route.query;
		window['receivePhotoResult'] = (base64) => {
			//console.log('拍照回调', base64.substring(0, 10))
			//_this.$utils.dialog.alert('拍照回调：' + base64.substring(0, 10));
			this.receivePhotoResult(base64);
		}
		window.getCameraData = (base64) => {
			//console.log('uni-app.face 拍照回调', base64.substring(0, 10))
			this.receivePhotoResult(base64);
		}
		if (this.$store.state.cache.screen.wx) {
			this.uploading = true
			wxConfig().then(res => {
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: res.appId, // 必填，公众号的唯一标识
					timestamp: res.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.nonceStr, // 必填，生成签名的随机串
					signature: res.signature,// 必填，签名
					jsApiList: ['chooseImage', 'uploadImage'] // 必填，需要使用的JS接口列表
				})
			})
			wx.ready(() => {
				_this.uploading = false
			})
			wx.error(err => {
				_this.uploading = false
				console.log('微信config出错 ===', err)
				this.$utils.dialog.alert(`微信config失败：${ err.errMsg }`)
			})
		}
	},
	methods: {
		clickAppPlay() {
			const _this = this
			if (this.uploading) return
			console.log('点击开始拍照按钮')
			localStorage.setItem('videoPlayNums', '0');	//初始化视频播放个数
			if (this.$store.state.cache.screen.wx) {
				wx.chooseImage({
					count: 1, // 默认9
					sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						_this.uploading = true
						wx.uploadImage({
							localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
							isShowProgressTips: 1, // 默认为1，显示进度提示
							success: function (uploadRes) {
								wxCompareFace({mediaId: uploadRes.serverId}).then(() => {
									_this.uploading = false;
									_this.$store.commit('cache/set_face', {
										key: 'signInPhoto',
										value: uploadRes.serverId
									})
									// _this.$router.push('/train')
									_this.uploadPhoto('', uploadRes.serverId)
								}).catch(() => {
									_this.uploading = false;
									_this.$refs.imageInput.value = '';
									_this.avatar = '';
								})
							},
							fail: function () {
								_this.uploading = false
							}
						})
					}
				})
			} else {
				console.log('进入APP 操作区域')
				if (_this.$store.state.cache.screen.isAndroid) {
					console.log('进入 安卓 操作区域')
					if (uni) {
						uni.postMessage({
							data: {
								action: 'setCamera'
							}
						});
						//return;
					}
					console.log('调用安卓方法：')
					if (!window.Android_JS) {
						console.log('安卓方法Android_JS不存在');
					}
					if (!window.Android_JS.androidPhoto) {
						console.log('安卓方法androidPhoto不存在');
					}
					if (window.Android_JS && window.Android_JS.androidPhoto) {
						window.Android_JS.androidPhoto();
					}
				}
				if (_this.$store.state.cache.screen.isIos) {
					console.log('进入 iOS 操作区域')
					if (uni) {
						uni.postMessage({
							data: {
								action: 'setCamera'
							}
						});
						//return;
					}
					console.log('调用IOS方法：')
					if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosPhoto) {
						console.log('调用IOS方法成功')
						window.webkit.messageHandlers.iosPhoto.postMessage(null);
					}
				}
			}
		},
		receivePhotoResult(base64) {
			const _this = this;
			this.uploading = true;
			this.avatar = base64;
			compareFace({photo: base64}).then(() => {
				_this.uploading = false;
				_this.$store.commit('cache/set_face', {key: 'signInPhoto', value: base64})
				// _this.$router.push('/train')
				_this.uploadPhoto(base64)
			}).catch(() => {
				_this.uploading = false;
				_this.$refs.imageInput.value = '';
				_this.avatar = '';
			})
		},
		imgChange(e) {
			console.log('调用 js 拍照方法')
			if (this.uploading) return
			this.uploading = true;
			let file = e.target.files[0];
			const _this = this;
			new Compressor(file, {
				quality: 0.9,
				maxWidth: 413,
				maxHeight: 626,
				convertTypes: ['image/jpeg'],
				convertSize: 1000000,
				success(result) {
					let reader = new FileReader();
					reader.readAsDataURL(result);
					reader.onload = (event) => {
						_this.avatar = event.target.result;
						compareFace({photo: event.target.result}).then(() => {
							_this.$store.commit('cache/set_face', {key: 'signInPhoto', value: event.target.result})
							_this.uploading = false
							_this.uploadPhoto(event.target.result)
						}).catch(() => {
							_this.uploading = false;
							_this.$refs.imageInput.value = '';
							_this.avatar = '';
						})
					};
					_this.avatar = window.URL.createObjectURL(result)
				},
				error(err) {
					console.log(err);
					_this.$utils.dialog.alert('照片压缩时出错，请重拍');
				}
			});
		},
		/**
		 * 签到 并 提交 相片
		 * @param { string } base64
		 * @param { string } mediaId
		 */
		uploadPhoto (base64, mediaId = '') {
			const _this = this
			const query = this.$route.query
			_this.uploading = true;
			signIn({
				studentId: this.$store.state.user.userid,
				belongCategory: query.belongCategory,
				unitType: query.unitType,
				signInIp: window.location.hostname,
				signPic: base64,
				mediaId: mediaId,
				courseId: query.courseid,
			}).then(res => {
				_this.uploading = false;
				if(res && res.isNewSign == 1) {
					localStorage.clear();
				}
				// sessionStorage.setItem('signId', res.basePkId);
				localStorage.setItem('videoPlayNums', '0');	//初始化视频播放个数
				_this.$store.dispatch('SetCourseId', query.courseid)
				if(this.$route.query.activeSubject == 2 || this.$route.query.activeSubject == 3){
					_this.$router.replace({
					// path: '/video',
						path: '/question', // 重绘学习界面
						query: {
							subject: query.belongCategory,
							courseid: query.courseid,
							signId: res.basePkId,
							idCard: query.idCard,
						}
					})
				}else{
					if(this.$route.query.mediaPlayer == 2){
						_this.$router.replace({
						// path: '/video',
							path: '/study1', // 重绘学习界面
							query: {
								subject: query.belongCategory,
								courseid: query.courseid,
								signId: res.basePkId
							}
						})
					}else{
						_this.$router.replace({
						// path: '/video',
						path: '/study', // 重绘学习界面
						query: {
							subject: query.belongCategory,
							courseid: query.courseid,
							signId: res.basePkId
						}
						})
					}
				}
			}).catch(() => {
				_this.uploading = false;
				this.$store.commit('cache/set_face', {key: 'signInPhoto', value: ''})
			})
		},
		upload(file) {
			if (this.avatar) return;

			const _file = file;
			const isLt2M = _file.size / 1024 / 1024 < 20;
			if (!isLt2M) {
				this.showAlert("请上传20M以下图片");
				return false;
			}

			// 通过 FormData 对象上传文件
			let formData = new FormData();
			formData.append("photo", _file);
			this.uploading = true;
			compareFace(formData).then((data) => {
				this.avatar = data.url;
				this.$router.push({path: '/train', query: {imgurl: this.avatar}})
			}).catch(() => {
				this.uploading = false;
				this.$refs.imageInput.value = '';
				this.avatar = '';
			})
		},
		navBack() {
			if (sessionStorage.getItem('anhuihefei') === '1') {
				this.$router.back();
			} else {
				this.$router.push({path: '/train'});
			}
		}
	},
};
</script>

<style lang="less" scoped>
.header {
	position: absolute;
	width: 100%;
	height: 200px;
	padding-bottom: 14px;
	border-bottom-left-radius: 26px 10px;
	border-bottom-right-radius: 26px 10px;
}

.tips {
	padding: 4px 12px;
	font-size: 12px;
}

.page-container {
	min-height: 100vh;
	background: #f5f5f5;
}

.page-body {
	margin-top: 0;
	padding: 100px 15px 15px;

	.content {
		position: relative;
		top: -14px;
		background: #fff;
		border-radius: 14px;
		padding: 15px 15px 35px;

		.section-top {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;


			h4 {
				font-size: 18px;
				color: #333;
				margin: 20px 0;
			}

			.desc {
				font-size: 14px;
				color: #666;
				margin: 0 0 20px;
			}

			.img-face {
				width: 100%;
				margin: 20px auto 40px;
			}

			.comment {
				color: #666;
				text-align: left;
				margin-bottom: 40px;
				padding: 0 20px;

				P {
					margin: 0 0 20px;
					font-size: 13px;
				}
			}

			span {
				color: #666;
				font-size: 15px;
				margin-bottom: 40px;
			}
		}

		.section-bottom {
			position: relative;

			.img-input {
				display: block;
				position: absolute;
				top: 0;
				width: 80%;
				left: 10%;
				right: 10%;
				margin: auto;
				height: 40px;
				opacity: 0;
			}
		}
	}
}

// .van-popup {
//   background: transparent;
// }
.van-popup .van-popover__arrow {
	background: #fca142;
}

.van-popover__content {
	background: #fca142;
	color: #fff;
}

.van-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.circle-wrap {
	position: relative;
	height: 200px;

	.circle {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 160px;
		height: 160px;
		margin: -80px 0 0 -80px;
		background-image: url(~@/assets/icon-circle-sm.png);
		background-size: cover;

		.circle-inner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;

			.title {
				margin: 0 0 4px 0;
				color: #ff8506;
				font-size: 20px;
			}

			span {
				color: #999;
				font-size: 11px;
			}
		}
	}
}

.popup-content {
	width: 290px;
	// border-radius: 12px;
	overflow: hidden;
	background: #fff;

	& > .title {
		background: linear-gradient(90deg, #FCA142, #FF8506);
		padding: 15px;
		font-size: 18px;
		color: #fff;
		text-align: center;
	}

	.content {
		margin: 20px 0 10px;
		padding: 0 10px;
		font-size: 12px;

		.list {
			display: flex;
			align-items: center;
			margin-top: 10px;

			span {
				display: inline-block;
				width: 50%;
			}
		}
	}

	.btn-wrap {
		display: flex;
		padding: 15px;

		.btn {
			width: 40%;
			font-weight: 500;
		}
	}
}
</style>

